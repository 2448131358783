
.resume {
    border: 2px solid #282c34;
    padding: 4px 6px;
}


h2 {
    border-top: 2px solid #282c34;
    margin: 16px 0px 0px 0px;
    padding-top: 16px;
    text-align: center;
}

.header {
    position: relative;
    background-color: #282c34;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: white;
}

.linkedin-link {
    position: absolute;
    top: 4px; /* Adjust as needed */
    right: 0px; /* Adjust as needed */
    color: #0077b5; /* LinkedIn blue */
    text-decoration: none;
    display: inline-flex;
    align-items: center;
}

.linkedin-link:hover {
    color: #005582; /* Darker blue on hover */
    text-decoration: underline;
}

.linkedin-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.mailto-link {
    color: white; /* Set the color you want */
    text-decoration: none; /* Optional: Remove underline */
}

.mailto-link:hover {
    color: floralwhite; /* Optional: Change color on hover */
    text-decoration: underline; /* Optional: Underline on hover */
}

.centered {
    text-align: center;
}

.centered-emphasis
{
    font-style: italic;
    text-align: center;
}

.tight-centered {
    margin: 2px;
    text-align: center;
}

.tight-centered-emphasis {
    margin: 2px;
    font-style: italic;
    text-align: center;
}

.tight {
    margin: 2px;
}

.bold {
    font-weight: bold;
}

.underline {
    text-decoration: underline;
}

.text-carousel {
    margin-top: 2px;
    border-top: 2px solid #282c34;
    text-align: center;
    margin-bottom: 6px;
    border-bottom: 3px solid #282c34;
}

.job-container2 {
    display: flex;
    flex-direction: column;
}

.job-header2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.job-title2 {
    flex: 0;
    font-weight: bold;
    font-size: large;
    white-space: nowrap;
}

.date2 {
    margin-left: 1px;
    margin-right: 1px;
    text-align: left;
    white-space: nowrap;
}

.company2 {
    margin-left: auto;
}

    .company a {
        text-decoration: none;
        color: inherit;
        /* Add any additional styling here */
    }

/* For the Job Entries */
.job-container {
    margin-top: 6px;
    margin-bottom: 1px;
    display: flex;
    flex: 1;
}

.job-header {
    width: 100%;
    table-layout: auto;
}

.job-title {
    font-weight: bold;
    font-size: large;
    white-space: nowrap;
}

.date {
    text-align: left;
    white-space: nowrap;
}

.company {
    text-align: right;
    width: 100%;
}

.job-description {
    margin-top: 1px;
}

.technical-scope {
    margin-top: 0px;
}

/* Show Images on Hover */
.hover-section {
    cursor: pointer;
    position: relative;
}

.hover-image-container {
    position: absolute;
    top: 25%;
    left: -25%;
    z-index: 1000;
    opacity: 0;
    transition: opacity 3s ease-in-out;
}

    .hover-image-container.fade-in {
        opacity: .7;
    }

    .hover-image-container.fade-out {
        opacity: 0;
    }

.hover-image-container-right {
    position: absolute;
    top: 25%;
    left: 70%;
    z-index: 1000;
    opacity: 0;
    transition: opacity 3s ease-in-out;
}

    .hover-image-container-right.fade-in {
        opacity: .7;
    }

    .hover-image-container-right.fade-out {
        opacity: 0;
    }

.hover-image {
    width: 400px;
    height: auto;
}